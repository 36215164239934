export default class Pagenation {
  constructor() {
    this.count = 0;
    this.take = 0;
    this.page = 1;
    this.count = 0;
  }

  get length() {
    return Math.ceil(this.count / this.take);
  }

  get skip() {
    return this.take * (this.page - 1);
  }
}
