<template>
  <v-container>
    <v-card-title>
      <h3>施設管理</h3>
      <v-spacer />
      該当件数:{{ pagenation.count }}
    </v-card-title>
    <v-card-actions>
      <v-text-field
        label="キーワード"
        outlined
        v-model="filter.word"
        placeholder="名称,電話番号,住所,コードなどから部分検索します スペース区切りが可能です"
        persistent-placeholder
        :hint="$options.filters.implode(splited)"
        persistent-hint
        dense
        @input="onWord"
      />
      <v-checkbox class="ml-2" label="重複のみ" dense v-model="filter.duplicatedTelephoneNo" @change="onSearchAsync" />

      <v-spacer />
      <v-btn outlined color="primary" @click="onSearchAsync">検索</v-btn>
    </v-card-actions>

    <v-pagination v-model="pagenation.page" :length="pagenation.length" @input="onPageChanged"></v-pagination>

    <v-list three-line>
      <v-list-item v-for="(facility, index) in facilities" :key="index">
        <!-- <v-list-item-avatar>
        <v-img src="https://cdn.vuetifyjs.com/images/lists/3.jpg"></v-img>
      </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>
            <v-menu
              v-if="facility.duplicatedTelephoneNos.length > 0"
              :close-on-content-click="false"
              :nudge-width="200"
              top
              offset-y
              open-on-hover
              :close-on-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="warning" v-bind="attrs" v-on="on">重複({{ facility.duplicatedTelephoneNos.length }})</v-chip>
              </template>

              <v-list>
                <v-list-item v-for="(data, j) in facility.duplicatedTelephoneNos" :key="j">
                  <!-- <v-list-item-avatar>
                  <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
                </v-list-item-avatar> -->

                  <v-list-item-content>
                    <v-list-item-title>{{ data.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text--primary">〒{{ data.postalCode }}{{ data.prefName }}{{ data.address1 }}{{ data.address2 }}</span>
                      &mdash;
                      {{ data.tel }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text>{{ data.code }}</v-list-item-action-text>
                    <v-btn text color="primary" @click="onShowEditor(data.code)">編集</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            {{ facility.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="text--primary"
              >〒{{ facility.postalCode }}{{ facility.prefName }}{{ facility.address1 }}{{ facility.address2 }}</span
            >
            &mdash;
            {{ facility.tel }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>{{ facility.code }}</v-list-item-action-text>
          <v-btn text color="primary" @click="onShowEditor(facility.code)">編集</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-pagination v-model="pagenation.page" :length="pagenation.length" @input="onPageChanged"></v-pagination>

    <ValidationObserver v-slot="observer">
      <v-bottom-sheet scrollable v-model="editor.show" persistent inset>
        <FacilityEdit
          :show="editor.show"
          :observer="observer"
          :facilityCode="editor.code"
          @commit="
            editor.show = false;
            onSearchAsync();
          "
          @cancel="editor.show = false"
        />
      </v-bottom-sheet>
    </ValidationObserver>
  </v-container>
</template>


<script>
// import FacilityCreate from '@/components//facilities/Create';
import FacilityEdit from '@/components/facilities/Edit';
import Pagenation from '@/util/pagination';
const Page = 200;

export default {
  components: {
    // FacilityCreate,
    FacilityEdit,
  },

  computed: {
    splited() {
      let word = this.filter.word;
      if (word == null) return [];
      //
      let res1 = word.split(' ');
      let res2 = res1.flatMap((a) => a.split('　'));
      return res2;
    },
  },

  data: () => ({
    pagenation: new Pagenation(),

    filter: {
      word: null,
    },

    facilities: [],

    creator: {
      show: false,
    },

    editor: {
      show: false,
      code: null,
    },
  }),

  watch: {},

  methods: {
    async onLoaded() {
      await this.onSearchAsync();
    },

    async onSearchAsync() {
      //
      console.log(this.pagenation);

      this.pagenation.take = Page;

      let request = this.filter;
      request.wordsValue = this.splited.join(',');
      request.skip = this.pagenation.skip;
      request.take = this.pagenation.take;

      //
      this.get('facilities', request).then((success) => {
        let data = success.data;

        this.pagenation.count = data.count;
        //
        this.facilities = data.datas;
      });
    },

    async onWord() {
      await this.onSearchAsync();
    },

    async onPageChanged(page) {
      this.pagenation.page = page;

      await this.onSearchAsync();
    },

    onShowEditor(code) {
      //
      this.editor.show = true;
      this.editor.code = code;
    },
  },
  created() {
    this.onLoaded();
  },
};
</script>